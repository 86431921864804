@import '../layout/color.scss';

.preview-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  //   background: red;
}
.img-preview {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid rgb(234, 234, 234);
  margin-bottom: 8px;
  margin-right: 8px;
  width: 80px;
  height: 80px;
  padding: 4px;
  box-sizing: border-box;
}

.img-preview-alternate {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid rgb(234, 234, 234);
  margin-bottom: 8px;
  margin-right: 8px;
  width: 300px;
  height: 300px;
  padding: 4px;
  box-sizing: border-box;
}

.vid_preview {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid rgb(234, 234, 234);
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100%;
  height: 100%;
  padding: 4px;
  box-sizing: border-box;
}

.close-btn.MuiAvatar-colorDefault {
  float: right;
  margin-right: -18px;
  margin-left: -6px;
  width: 20px;
  height: 20px;
  background: #ff4568;
  .MuiSvgIcon-root {
    color: white;
    font-size: 15px;
  }
}

.close-btn-alternate.MuiAvatar-colorDefault {
  float: right;
  margin-left: 284px;
  margin-right: -18px;
  margin-top: -6px;
  width: 20px;
  height: 20px;
  background: #ff4568;
  .MuiSvgIcon-root {
    color: white;
    font-size: 15px;
  }
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
// .react-tel-input {
//   width: 50%;
//   font-size: 15px;
//   position: relative;
// }
// .react-tel-input .form-control {
//   width: 50%;
// }
// .phone-no-input {
//   font-size: 15px;
//   position: relative;
//   width: 50%;
// }
.react-tel-input {
  width: 50%;
  .form-control {
    padding: 14.5px 12px 14.5px 58px;
    width: 100%;
  }
}

.multipurpose {
  width: 100%;
  .multipurpose-content {
    // display: flex;
    width: 100%;

    .document-files {
      width: 80px;
      height: 80px;
    }
    .img-wrapper {
      .MuiListItemAvatar-root {
        min-width: 180px;
        flex-shrink: 0;
      }
    }
    .img-files {
      width: 200px;
      height: 150px;
      object-fit: cover;
    }
  }
  .multipurpose-fileupload {
    display: flex;
    width: 100%;
    padding: 12px 0px;
    .avatar-wrapper {
      text-align: center;
    }
    .MuiAvatar-root {
      margin: 0px 12px;
      width: 50px;
      height: 50px;
      background: #e5edff;
      color: $primary-color;
      cursor: pointer;
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }
  }
}

/****************************************  Country phone input ************************************************/
.country {
  min-height: 48px;
  padding: 5px 10px;
  align-items: center;
  display: flex;
}

.MuiIntlTelField-root {
  height: 100%;
}

.MuiOutlinedIntlTelInput-input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 100% !important;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 0;
}

.MuiOutlinedIntlTelInput-input:focus {
  outline: 0;
}

.MuiOutlinedIntlTelInput-root {
  height: 80%;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-radius: 4px;
  margin-top: 10px;
}

.MuiIntlTelField-root .MuiFormLabel-filled {
  max-width: calc(133% - 24px);
}

.MuiOutlinedIntlTelInput-root:hover .MuiOutlinedIntlTelInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.87);
}

.MuiOutlinedIntlTelInput-root.Mui-focused
  .MuiOutlinedIntlTelInput-notchedOutline {
  border-color: #ff9800;
  border-width: 2px;
}

.MuiOutlinedIntlTelInput-root:not(.Mui-focused):not(.Mui-error):hover
  .MuiOutlinedIntlTelInput-notchedOutline {
  border-color: black; /* Black when hovered */
  border-width: 1px; /* Maintain border width */
}

.MuiOutlinedIntlTelInput-notchedOutline {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
}

.MuiOutlinedIntlTelInput-notchedOutline legend,
.MuiStandardIntlTelInput-notchedOutline legend {
  display: block;
  width: auto;
  padding: 0;
  height: 11px;
  font-size: 0.75em;
  visibility: hidden;
  max-width: 0.01px;
  -webkit-transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.MuiOutlinedIntlTelInput-notchedOutline legend > span,
.MuiStandardIntlTelInput-notchedOutline legend > span {
  display: inline-block;
}

.MuiOutlinedIntlTelInput-root.Mui-filled fieldset legend,
.MuiOutlinedIntlTelInput-root.Mui-focused fieldset legend,
.MuiStandardIntlTelInput-root.Mui-filled fieldset legend,
.MuiStandardIntlTelInput-root.Mui-focused fieldset legend {
  display: block;
  width: auto;
  padding: 0;
  height: 11px;
  font-size: 0.75em;
  visibility: hidden;
  max-width: 100%;
  -webkit-transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
  transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
}

.MuiStandardIntlTelInput-root:hover .MuiOutlinedIntlTelInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.87);
}

.MuiOutlinedIntlTelInput-root.Mui-focused
  .MuiStandardIntlTelInput-notchedOutline {
  border-color: #ff9800;
  border-width: 0px 0px 2px 0px;
}

.MuiOutlinedIntlTelInput-root:not(.Mui-focused):not(.Mui-error):hover
  .MuiStandardIntlTelInput-notchedOutline {
  border-color: black;
  border-width: 0px 0px 2px 0px;
}

.MuiOutlinedIntlTelInput-root.Mui-error
  .MuiStandardIntlTelInput-notchedOutline {
  border-color: #e53935;
  border-width: 0px 0px 2px 0px;
}

.MuiOutlinedIntlTelInput-root.Mui-error:not(.Mui-focused)
  .MuiOutlinedIntlTelInput-notchedOutline {
  border-color: #e53935;
  border-width: 1px;
}

.MuiOutlinedIntlTelInput-root.Mui-error
  .MuiOutlinedIntlTelInput-notchedOutline {
  border-color: #e53935;
  border-width: 2px;
}

.MuiStandardIntlTelInput-notchedOutline {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  pointer-events: none;
  border-radius: none;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
}

.MuiOutlinedIntlTelInput-notchedOutline legend,
.MuiStandardIntlTelInput-notchedOutline legend {
  display: block;
  width: auto;
  padding: 0;
  height: 11px;
  font-size: 0.75em;
  visibility: hidden;
  max-width: 0.01px;
  -webkit-transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.MuiIntlLabel.Mui-focused {
  color: #ff9800 !important;
}
.MuiIntlLabel.Mui-error {
  color: #e53935 !important;
}

/* Helper text styling */
.MuiIntlTelField-root .MuiFormHelperText-root {
  margin-left: 0;
  margin-right: 0;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
  line-height: 1.66;
  font-family: Poppins, sans-serif;
  font-weight: 400;
}

.SmallInputText {
  font-size: 12px;
}

.arrow {
  pointer-events: none;
}
/****************************************  Country phone input ************************************************/
